import axios from 'axios'
import { BASE_URL } from "../../../../Store/snippets"
import { setSentryUser } from '../../../../Utils/sentry'

export const SignIn = async ({ phone, password }) => {
	localStorage.setItem('phone_login', JSON.stringify(phone))
	localStorage.setItem('simpleForm', JSON.stringify(false))

	try {
			const response = await axios.post(BASE_URL + '/auth',
					{
							"Login": phone.replace(/\s/g, ''),
							"Password": password
					}
			)
			const { Token, User } = response.data.Data

			localStorage.setItem('tokken', Token);
			localStorage.setItem('USER', JSON.stringify(User));
			localStorage.setItem('BASE_URL', BASE_URL);
			const m = new Map()
			localStorage.setItem("DASHBOARD_MAP", JSON.stringify(Array.from(m.entries())))
			document.cookie = 'tokken=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
			document.cookie = `tokken = ${Token};max-age=86340;path=/`;

			setSentryUser({ phone, IdShift: User.IdShift });

			return { response: response.data.Data }
	} catch (error) {
			if (error.response === undefined) {
				return {
					head: 'Отсутствует связь с сервером',
					message: 'Проверьте соединение с интернетом или обратитесь в тех. поддержку',
				}
			} else if (error.response.status > 501) {
				return {
					head: 'Ошибка входа',
					message: 'Аппаратно-программный комплекс Fiscal24 не отвечает, повторите попытку еще раз',
				}
			} else if (error.response.status === 401) {
				return {
					head: 'Ошибка входа',
					message: 'Неверный логин или пароль',
				}
			}
	}
}