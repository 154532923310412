
import { Capacitor } from '@capacitor/core';
import * as SentryReact from "@sentry/react";
import * as SentryCapacitor from "@sentry/capacitor";

export const captureSentryException = (error) => {
  if (Capacitor.isNativePlatform()) {
    SentryCapacitor.captureException(error);
  } else {
    SentryReact.captureException(error);
  }
};

export const setSentryUser = (user) => {
  if (Capacitor.isNativePlatform()) {
    SentryCapacitor.setUser(user);
  } else {
    SentryReact.setUser(user);
  }
};